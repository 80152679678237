.header{
    display: flex;
}
.total{
    display: flex;
    font-weight: bolder;
}
.filter-card{
    background: white;
    /*border-radius: 0.5rem;*/
    padding: 1rem 1.5rem 1.5rem;
    margin-bottom: 1rem;
    box-shadow:
            0px 2px 1px -1px rgb(0 0 0 / 20%),
            0px 1px 1px 0px rgb(0 0 0 / 14%),
            0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.filter-btn{
    display: flex;
    justify-content: end;
    align-items: end;
}
.filter-button{
    background: dodgerblue!important;
    color: #fff!important;
    padding: 0.5rem 1.2rem!important;
    margin-left: auto;
}
.css-1kpdewa-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
    padding: 6px 0 3px!important;
}