
:root {
    --text-color: #124a63;
    --text-color-secondary: #b3c5cd;

    --primary-color: #b3c5cd;

    --background-color: #c00a27;
    --background-color-dark: #eef3f6;
    --background-color-light: #fff;

    /*--font-family: "Poppins", sans-serif;*/
    --box-shadow: 0px, 2px, 8px, rgba(0,0,0,0.05);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter' !important;
}

html {
    scroll-behavior: smooth;
}
.react-kanban-card{
    background: linear-gradient(45deg, rgba(0, 238, 147, 0.33) 0%, rgba(82, 207, 255, 0.33) 50%);
    padding: 0!important;
}
/* .react-kanban-column {
  background: whitesmoke;
} */
.react-kanban-card__title {
    border-bottom: 1px solid #eee;
    padding-bottom: 0px!important;
    font-weight: bold;
    /* display: flex; */
    /* justify-content: space-between; */
}
.react-kanban-card__description{
    padding: 0!important;
}
.PrivateNotchedOutline-root-73 ,.PrivateNotchedOutline-root-89{
    top: 0px!important;
}
.MuiInputLabel-outlined.MuiInputLabel-marginDense {
    background: white;
}
.status{
    height: 1.5rem;
    margin-left: auto;
    width: fit-content;
    border-radius: 1.5rem;
    margin-top: 0.2rem;
    transition: 0.5s ease-in-out;
    padding: 0.1rem 0.7rem;
    font-weight: 500;
}
.new {
    width: 33%;
    background: deepskyblue;
}
.progress{
    width: 66%;
    background: yellow;
}
.done{
    /*width: 100%;*/
    background: rgba(32, 178, 170, 0.2);
    color: rgba(32, 178, 170, 1);
}