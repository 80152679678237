.ring-container {
    position: relative;
    top:-28px; right:44px;
    /* background: red !important; */
}

.circle {
    width: 13px;
    height: 13px;
    background-color: #62bd19;
    border-radius: 50%;
    position: absolute;
    top: 21px;
    left: 21px;
}

.ringring {
    border: 3px solid #62bd19;
    border-radius: 30px;
    height: 25px;
    width: 25px;
    position: absolute;
    left: 15px;
    top: 15px;
    animation: pulsate 1.35s ease-out;
    animation-iteration-count: infinite; 
    opacity: 0.0
}
@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}